wow = new WOW(
{ 
  mobile:false 

})
wow.init();

(function($) {


  $(document).ready(function() {
	 
        $('img.svg').each(function(){
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                
                               
                var $svg = jQuery(data).find('svg');
				$svg = $svg.remove('style');
                
//                 console.log( $svg );
                

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');
               

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');
        }); // img.svg
        
        
        $('.single-job #career-subject').val( 'Ogłoszenie: ' + $('.type-job h1').text() );


/*
		if( $( window ).width() < 767 ) {

	        $('.dropdown > a').attr({
		        'href': '#',
		        'data-toggle': 'dropdown'
	        });
        
        }
*/
        
        
		$('.file-cv input').change(function () {
			var $txt = $( this ).parents('.file-cv').find('.txt');
			var $this = $( this );
			
			
			$txt.html( $this.val() ? $this.val().replace(/C:\\fakepath\\/i, '') : 'Wstaw załącznik' )
			
			console.log( $this );
		});
		
		
		
		$('.tab-content a[href$=".jpg"]').click(function (event) {
			event.preventDefault();
			
			var $this = $( this );
			
			var url = $this.attr('href');
			lity( url );
			
		});
		
		
		function toggleIcon(e) {
		    $(e.target)
		        .prev('.panel-heading')
		        .find(".more-less")
		        .toggleClass('glyphicon-plus glyphicon-minus');
		    $(e.target)
		        .prev('.panel-heading')
		        .find("a")
		        .toggleClass('font-plus font-minus');    
		}
		$('.panel-group').on('hidden.bs.collapse', toggleIcon);
		$('.panel-group').on('shown.bs.collapse', toggleIcon);
		
		
		
		
	var el1 = $('.circle1'),    
    i1 = '',
	$val1 = (parseFloat($('.circle1').data('nvalue')));

	var el2 = $('.circle2'),
    i2 = '',
	$val2 = (parseFloat($('.circle2').data('nvalue')));
	
	var el3 = $('.circle3'),
    i3 = '',
	$val3 = (parseFloat($('.circle3').data('nvalue'))),
	inited = false;

	el1.appear({ force_process: true });
	
	el1.on('appear', function() {
	  if (!inited) {
	    el1.circleProgress({
			    value: $val1,
			    size: 150,
			    fill: {
			      color: "#009fe3" 
			    },
			    emptyFill: "rgba(0, 0, 0, .1)",
			    lineCap: "square"
			  }).on('circle-animation-progress', function(event, progress) {
				if (el1.data('ivalue') != '') { i1 = "*";}
			    $(this).find('strong').html(Math.round($(this).data('pvalue') * progress) + '<i>%' + i1 + '</i>');
			  });
		el2.circleProgress({
			    value: $val2,
			    size: 150,
			    fill: {
			      color: "#009fe3" 
			    },
			    emptyFill: "rgba(0, 0, 0, .1)",
			    lineCap: "square"
			  }).on('circle-animation-progress', function(event, progress) {
				if (el2.data('ivalue') != '') { i2 = "*";}
			    $(this).find('strong').html(Math.round($(this).data('pvalue') * progress) + '<i>%' + i2 + '</i>');
			  });	  
		el3.circleProgress({
			    value: $val3,
			    size: 150,
			    fill: {
			      color: "#009fe3" 
			    },
			    emptyFill: "rgba(0, 0, 0, .1)",
			    lineCap: "square"
			  }).on('circle-animation-progress', function(event, progress) {
				if (el3.data('ivalue') != '') { i3 = "*";}
			    $(this).find('strong').html(Math.round($(this).data('pvalue') * progress) + '<i>%' + i3 + '</i>');
			  });
			  	  
	    inited = true;
	  }
	});	
		 

		$(window).scroll(function () {
		    if ($(document).scrollTop() > $( ".navbar-header" ).height()) {
			    	       	
		        $('header').addClass('section-bg-dark');
		        $('header').removeClass('navbar-bg-transparent');
		        
		        $('ul.navbar-nav').addClass('navbar-nav-scroll');
		        $('button.navbar-toggle').addClass('navbar-toggle-scroll');
		        $('a.navbar-brand').addClass('navbar-brand-scroll');	        
		        
		    } else {
			    $('header').addClass('navbar-bg-transparent');	
		        $('header').removeClass('section-bg-dark');	
		        
		        $('ul.navbar-nav').removeClass('navbar-nav-scroll');
		        $('button.navbar-toggle').removeClass('navbar-toggle-scroll');
		        $('a.navbar-brand').removeClass('navbar-brand-scroll');	    
		    }
		});


		$('.scroll-top').click(function(event) {
			event.preventDefault();
			
			
			var body = $("html, body");
			body.stop().animate({
				scrollTop: 0
			}, 500, 'swing');
			
			
			return false;
		});
		
		
		$('.btn-scroll-down').bind('click', function(event) {
			var $anchor = $(this);
			$('html, body').stop().animate({
            	scrollTop: $($anchor.attr('href')).offset().top - $( ".navbar-header" ).height()
        		}, 500, 'swing');
				event.preventDefault();
    	});
    	
    	
    	//
		//scroll to anchor on another page
		//
		$('html').css({display: 'none'});

		$(document).ready(function(){
		    var hashURL = location.hash;
		    if(hashURL != "" && hashURL.length > 1){
		        $(window).scrollTop(0);
		        $('html').css({display: 'block'});
		        smoothScrollTo(hashURL);
		    } else {
		        $('html').css({display: 'block'});
		    }
		});		
		
		function smoothScrollTo(anchor) {
		    var targetY = $(anchor).offset().top - $( ".navbar-header" ).height();
		    $("html, body").animate({
		        "scrollTop" : targetY
		    }, 1000, 'swing');
		}
		//end anchor
		
		
		
		
		
		
		
 
		// $('ul#menu-glowne-menu li a, .btn-scroll-down').bind('click', function(event) {
			// var $anchor = $(this);
			// $('html, body').stop().animate({
            	// scrollTop: $($anchor.attr('href')).offset().top - $( ".navbar-header" ).height()
        		// }, 500, 'swing');
				// event.preventDefault();
    	// });
 
		
		lightbox.option({
		  'albumLabel':  "%1/%2"
		});
		
		
		
		
		$('#newsletter').submit(function (event) {
			event.preventDefault();
			
			var data = $( this ).serialize();
			
			$.post( v.ajaxurl, data, function (data) {
				var info = $('#newsletter_modal');
				if( data.status ) {
					info.find('.title').text('Dziękujemy!');
					info.find('.text').text('Pomyślnie zapisałeś się do newslettera Scandagra Polska.');
					info.modal('show');
				} else {
					info.find('.title').text('Błąd');
					info.find('.text').text('Wystąpił błąd, prosimy spróbować poźniej');
					info.modal('show');
				}
			});
			
		});
        

  });
  

	
	


})(jQuery);





var Slider = (function($)
{
	var _public = {
		
		slider: function()
		{
			$('.slider-section').flexslider({
				controlNav: true,
				directionNav: false,  
			});
			
			$('.slider-products').flexslider({
				controlNav: true,
				directionNav: false,  
				animation: "slide", 
			});
			
			$('.text-slider').flexslider({
				controlNav: true,
				directionNav: false,
				smoothHeight: true,
				prevText: '',
				nextText: '',
			});
			
			$('.product-slider').flexslider({

				directionNav: false,
				manualControls: ".product-thumbnails li",
				slideshow: true,
// 				smoothHeight: true,
			});
		},
		
		init: function()
		{
			$( document ).ready( _public.slider );
		}
	}
	
	
	return _public;
}(jQuery)).init();


